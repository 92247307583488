import { zodResolver } from '@hookform/resolvers/zod';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; // Import Material-UI Add Icon
import {
  ADMIN_STATUS,
  ApprovedAdType,
  CREATIVE_STATUS,
  LINK_AD_DATA,
  SEARCH_CREATIVE_REQUESTS_RESPONSE,
} from 'API';
import classNames from 'classnames';
import { UseGetCreativeRequest } from 'hooks';
import { UseLinkApprovedAds } from 'hooks/query/useApprovedAds';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TextInput } from '../TextInput';
import ErrorModal from './components/ErrorModal/ErrorModal';
import {
  formSchema,
  FormSchema,
  LinkAdDialogTitle,
  linkApprovedAdsTypesConstant,
} from './constants';
import { filterDuplicate } from './helper';

interface ICreativeDetailsProps {
  creativeRequest?: SEARCH_CREATIVE_REQUESTS_RESPONSE | null;
  onSuccess?: () => Promise<void>;
}

interface ILinkApprovedAdsPayload {
  adId?: string;
  approvedAdType?: string;
}

interface ILinkApprovedFormPayload {
  approvedAdsData: ILinkApprovedAdsPayload[];
}

export const CreativeDetails = ({
                                  creativeRequest: requestData,
                                  onSuccess,
                                }: ICreativeDetailsProps) => {
  const [isLoading, setIsLoading] = useState(false); // Data loading state
  const [isSubmitLoading, setIsSubmitLoading] = useState(false); // Submit button loading state

  const { linkAds, error } = UseLinkApprovedAds();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const { data: creativeRequest, getCreativeRequest } = UseGetCreativeRequest();

  const isApproved = useMemo(
      () =>
          creativeRequest?.adminApproval === ADMIN_STATUS.Approved &&
          creativeRequest?.status === CREATIVE_STATUS.Approved,
      [creativeRequest]
  );

  const approvedAds = useMemo(
      () => filterDuplicate(creativeRequest?.approvedAds?.items || []),
      [creativeRequest?.approvedAds?.items]
  );

  const {
    control,
    setValue,
    trigger,
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      approvedAdsData: approvedAds.length > 0
          ? approvedAds.map((approvedAd) => ({
            approvedAdType: (approvedAd?.approvedAdType as ApprovedAdType) || '',
            adId: approvedAd?.ad_id || '',
          }))
          : [{ adId: '', approvedAdType: '' }],
    },
    mode: 'onChange',
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'approvedAdsData',
  });

  const getRequest = async () => {
    if (requestData?.id) {
      setIsLoading(true);
      await getCreativeRequest({
        variables: { id: requestData.id },
        errorPolicy: 'ignore',
      });
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (!creativeRequest && !isInitiallyLoaded) {
      getRequest();
      setIsInitiallyLoaded(true);
    }
  }, [requestData]);

  const disabledFields = useMemo(() => {
    const approvedAds = creativeRequest?.approvedAds?.items || [];

    return approvedAds?.reduce(
        (aggr, approvedAd, currentIndex) => ({
          ...aggr,
          [currentIndex]:
          !isApproved ||
          (approvedAd?.isManuallyLinked !== undefined &&
              approvedAd.isManuallyLinked === false),
        }),
        {}
    );
  }, [creativeRequest?.approvedAds?.items, isApproved]);

  const updateFormValues = useCallback(() => {
    if (!creativeRequest) {
      return;
    }

    setValue(
        'approvedAdsData',
        approvedAds.length > 0
            ? approvedAds.map((approvedAd) => ({
              approvedAdType: (approvedAd?.approvedAdType as ApprovedAdType) || '',
              adId: approvedAd?.ad_id || '',
            }))
            : [{ adId: '', approvedAdType: '' }]
    );
  }, [creativeRequest, approvedAds]);

  useEffect(() => {
    updateFormValues();
  }, [creativeRequest]);

  const onSubmit = useCallback(
      async (formPayload: ILinkApprovedFormPayload) => {
        if (!creativeRequest?.id) {
          return;
        }

        try {
          setIsSubmitLoading(true); // Start submit loading
          await linkAds({
            variables: {
              creativeId: creativeRequest?.id,
              approvedAdsData: formPayload?.approvedAdsData
                  ?.filter(
                      (approvedAd) => approvedAd.adId || approvedAd.approvedAdType
                  )
                  ?.map((approvedAd) => ({
                    approvedAdType: approvedAd.approvedAdType,
                    adId: approvedAd.adId,
                    advertiserId: ' ',
                  })) as LINK_AD_DATA[],
            },
          });

          if (onSuccess) {
            await onSuccess();
          }

          reset({
            approvedAdsData: formPayload?.approvedAdsData, // Reset the form state
          });

          await getRequest();
          toast.success('Linked successfully');
        } catch (error) {
          console.error("Error during submission:", error);
        } finally {
          setIsSubmitLoading(false); // End submit loading
        }
      },
      [creativeRequest, linkAds, getRequest, onSuccess, reset]
  );


  useEffect(() => {
    if (!error) {
      return;
    }

    setIsErrorModalOpen(true);
    setIsLoading(false);
  }, [error?.message]);

  const onCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return isLoading ? (
      <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
      >
        <CircularProgress />
      </div>
  ) : (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px", width: "800px", padding: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "20px" }}>
            <IconButton
                onClick={() => append({ adId: '', approvedAdType: '' })}
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
            >
              <AddIcon />
            </IconButton>

            <button
                type="button"
                onClick={handleSubmit(onSubmit)}
                disabled={
                    !isValid ||
                    !isDirty ||
                    requestData?.status !== CREATIVE_STATUS.Approved ||
                    isSubmitLoading // Disable while submitting
                }
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "8px 16px",
                  borderRadius: "14px",
                  minWidth: "70px",
                  minHeight: "40px",
                  textAlign: "center",
                  border: "none",
                  cursor: "pointer",
                  opacity: !isValid || !isDirty || isSubmitLoading ? 0.5 : 1,
                }}
            >
              {isSubmitLoading ? (
                  <CircularProgress style={{ width: "16px", height: "16px", color: "#fff" }} />
              ) : (
                  'Save'
              )}
            </button>

          </div>

          <div
              style={{
                maxHeight: "600px",
                overflowY: "auto",
                width: "100%",
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
          >
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              {fields.map((field, index) => (
                  <div key={field.id} style={{ display: "flex", gap: "16px", marginBottom: "16px", width: "100%" }}>
                    <Controller
                        control={control}
                        name={`approvedAdsData.${index}.adId` as const}
                        render={({ field }) => (
                            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: "8px" }}>
                              {index === 0 && <span style={{ fontWeight: "bold" }}>AD ID</span>}
                              <TextInput
                                  fullWidth
                                  control={control}
                                  placeholder="Enter Ad ID"
                                  disabled={disabledFields[index + 1]}
                                  label="AD ID"
                                  sx={{
                                    width: "350px",
                                    minWidth: "200px",
                                  }}
                                  {...field}
                              />
                            </div>
                        )}
                    />

                    <Controller
                        control={control}
                        name={`approvedAdsData.${index}.approvedAdType` as const}
                        render={({ field }) => (
                            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: "8px" }}>
                              {index === 0 && <span style={{ fontWeight: "bold" }}>Channel</span>}
                              <FormControl fullWidth>
                                <InputLabel id={`approvedAdType-label-${index}`}>Channel</InputLabel>
                                <Select
                                    labelId={`approvedAdType-label-${index}`}
                                    id={`approvedADType-select-${index}`}
                                    label="Channel"
                                    sx={{
                                      width: "350px",
                                      minWidth: "200px",
                                    }}
                                    {...field}
                                    onChange={(event) => {
                                      field?.onChange?.(event);
                                      trigger(`approvedAdsData.${index}.adId`);
                                    }}
                                    value={field?.value || ''}
                                >
                                  {linkApprovedAdsTypesConstant.map((linkApprovedAd) => (
                                      <MenuItem key={linkApprovedAd.id} value={linkApprovedAd.value}>
                                        {linkApprovedAd.label}
                                      </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                        )}
                    />
                  </div>
              ))}
            </form>
          </div>
        </div>

        <ErrorModal
            isOpen={isErrorModalOpen}
            message={error?.message || ''}
            onClose={onCloseErrorModal}
            title={LinkAdDialogTitle.FailedToLink}
        />
      </div>
  );
};